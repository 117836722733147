import React, { useRef, useEffect, useState } from "react";
import { io } from "socket.io-client";

const socket = io("https://live-preview-server.marketrix.co"); // Connect to the Socket.IO server

function LivePreview() {
  const canvasRef = useRef(null);
  const [lastPosition, setLastPosition] = useState(null);
  const [screenshots, setScreenshots] = useState([]);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: window.innerWidth, // Use window.innerWidth for initial value
    height: window.innerHeight, // Use window.innerHeight for initial value
  }); // Default dimensions

  useEffect(() => {
    // Emit screen dimensions to server
    socket.emit("screen-dimensions", {
      width: window.innerWidth,
      height: window.innerHeight,
    });

    // Listen for updated screen dimensions from the server
    socket.on("screen-dimensions", (dimensions) => {
      setCanvasDimensions(dimensions);
    });

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (!canvas || !ctx) {
      console.error("Canvas or context is not available");
      return;
    }

    const drawLine = (startX, startY, endX, endY) => {
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.strokeStyle = "blue";
      ctx.lineWidth = 2;
      ctx.stroke();
    };

    const handleUserAction = (data) => {
      if (data.type === "mousemove" || data.type === "click") {
        if (lastPosition) {
          drawLine(lastPosition.x, lastPosition.y, data.x, data.y);
        }
        setLastPosition({ x: data.x, y: data.y });
      }
    };

    const handleScreenshot = (data) => {
      console.log("Screenshot received in client:", data.screenshotData);
      setScreenshots((prevScreenshots) => [
        ...prevScreenshots,
        data.screenshotData,
      ]);
    };

    socket.on("user-action", handleUserAction);
    socket.on("screenshot", handleScreenshot);

    return () => {
      socket.off("user-action", handleUserAction);
      socket.off("screenshot", handleScreenshot);
      socket.off("screen-dimensions"); // Clean up the listener
    };
  }, [lastPosition]);

  return (
    <div
      style={{
        position: "relative",
        width: canvasDimensions.width,
        height: canvasDimensions.height,
      }}
    >
      <canvas
        ref={canvasRef}
        width={canvasDimensions.width}
        height={canvasDimensions.height}
        style={{
          border: "1px solid black",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 88888,
        }}
      />
      {screenshots.map((screenshot, index) => (
        <img
          key={index}
          src={screenshot}
          alt={`Screenshot ${index}`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: canvasDimensions.width,
            height: canvasDimensions.height,
            opacity: 1,
            zIndex: 6666,
          }}
          onError={(e) => {
            console.error("Error loading image:", e);
          }}
        />
      ))}
    </div>
  );
}

export default LivePreview;
